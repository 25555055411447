#scrollContainer {
    display: flex;
    position: relative;
    gap: 2rem;
    margin-left: 5rem;
    margin-bottom: 5rem;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin-top: 50px;

    &::-webkit-scrollbar {
        display: none;
    }
}

.leftArrow {
    position: absolute;
    top: 30%;
    left: 7.25rem;
    background: #bc5612;
    width: 2.9375rem;
    height: 2.9375rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
    transition: all 0.3s ease-in-out;
}

.rightArrow {
    position: absolute;
    top: 30%;
    right: 1.25rem;
    background: #bc5612;
    width: 2.9375rem;
    height: 2.9375rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
    transition: all 0.3s ease-in-out;
}

.leftArrowIcon,
.rightArrowIcon {
    color: #fff;
    font-size: 25px;
}

.leftArrow:hover,
.rightArrow:hover {
    transform: scale(1.1);
}

.leftArrow:focus,
.rightArrow:focus {
    outline: none;
}

@media screen and (max-width: 768px) {
    #scrollContainer {
        margin-left: 3rem;
        gap: 1.2rem;
    }

    .leftArrow {
        position: absolute;
        top: 25%;
        left: 4.25rem;
        background: #bc5612;
        width: 2rem;
        height: 2rem;
    }

    .rightArrow {
        position: absolute;
        top: 25%;

        width: 2rem;
        height: 2rem;
    }

    .leftArrow img {
        width: 1.7rem;
        height: 1.7rem;
    }

    .rightArrow img {
        width: 1.7rem;
        height: 1.7rem;
    }

    .leftArrowIcon,
    .rightArrowIcon {
        color: #fff;
        font-size: 20px;
    }
}

@media screen and (max-width: 600px) {
    #scrollContainer {
        margin-left: 1rem;
        gap: 1.2rem;
    }

    .leftArrow {
        position: absolute;
        top: 25%;
        left: 1.5rem;
        background: #bc5612;
        width: 1.5rem;
        height: 1.5rem;
    }

    .rightArrow {
        position: absolute;
        top: 25%;
        right: 0.5rem;
        width: 1.5rem;
        height: 1.5rem;
    }

    .leftArrow img {
        width: 1.2rem;
        height: 1.2rem;
    }

    .rightArrow img {
        width: 1.2rem;
        height: 1.2rem;
    }

    .leftArrowIcon,
    .rightArrowIcon {
        color: #fff;
        font-size: 15px;
    }
}
