.dream-holiday-section {
    position: relative;
    width: 100%;
    height: 100vh; /* Adjust the height as needed */
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
  }
  
  .overlay h1 {
    font-size: 3rem; /* Adjust the size as needed */
    margin: 0;
    padding: 10px 20px;
  }
  
  .overlay p {
    font-size: 1.2rem; /* Adjust the size as needed */
    margin: 0;
    padding: 10px 20px;
  }
  