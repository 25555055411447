.recently-updated {
  padding: 20px;
  display: flex;
  justify-content: center; /* Align container horizontally in center */
}

.movies-scroll-container {
  margin-top: 2%;
  overflow: hidden;
  position: relative;
  padding-bottom: 20px;
  width: 70%; /* Ensure the container takes full width */
}

.movies-container {
  display: flex;
  transition: transform 0.5s ease; /* Smooth left scroll animation */
}

.movie {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 250px; /* Increase the width of the movie items */
  margin-right: 20px;
  border-radius: 10px;
  background-color: #fff; /* Add a background color */
}

.movie:last-child {
  margin-right: 0; /* Remove margin for last movie */
}

.movies-scroll-container h2 {
  color: white;
  text-align: center;
}

.movie-poster {
  width: 100%;
  height: 200px; /* Increase the height of the movie poster */
  border-radius: 10px 10px 0 0;
}

.movie-details {
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 0 0 10px 10px;
  text-align: left;
}

.movie-title {
  margin-top: 0;
  margin-bottom: 5px;
}

.movie-year {
  margin-top: 0;
  font-size: 14px;
  color: #666;
}

.prev-arrow,
.next-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ff9800; /* Add background color */
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 20px;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add box shadow for better visibility */
}

.prev-arrow {
  left: 10px;
}

.next-arrow {
  right: 10px;
}

.prev-arrow:hover,
.next-arrow:hover {
  background-color: #e68900; /* Slightly darker background on hover */
}

/* Media Queries for Responsive Design */
@media (max-width: 1200px) {
  .movie {
    width: 200px; /* Decrease the width of the movie items */
  }
}

@media (max-width: 992px) {
  .movie {
    width: 180px; /* Decrease the width of the movie items */
  }
}

@media (max-width: 768px) {
  .movie {
    width: 150px; /* Decrease the width of the movie items */
    margin-right: 15px; /* Adjust the margin between items */
  }

  .prev-arrow,
  .next-arrow {
    width: 35px;
    height: 35px;
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .movie {
    width: 120px; /* Decrease the width of the movie items for small screens */
    margin-right: 10px; /* Adjust the margin between items */
  }

  .movie-poster {
    height: 150px; /* Adjust the height of the movie poster */
  }

  .prev-arrow,
  .next-arrow {
    width: 30px;
    height: 30px;
    font-size: 16px;
  }
}
