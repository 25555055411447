/* src/components/AboutUs.css */
.about-us-wrapper {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  /* Banner Section */
  .about-banner {
    background: url('https://images.unsplash.com/photo-1527004013197-339bc3f7e63c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&q=80&w=1080') no-repeat center/cover;
    text-align: center;
    padding: 60px 20px;
    color: #fff;
  }
  
  .about-banner h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .about-banner p {
    font-size: 1.2rem;
  }
  
  /* Why Choose Us */
  .why-choose-us {
    padding: 40px 20px;
    background-color: #f9f9f9;
  }
  
  .why-choose-us h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .choose-us-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .grid-item {
    background: #fff;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    text-align: center;
  }
  
  /* Mission, Vision & Values */
  .mission-vision-values {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 40px 20px;
  }
  
  .mv-section {
    flex: 1 1 calc(33.333% - 20px);
    background: #f0f0f0;
    padding: 20px;
    border-radius: 5px;
  }
  
  .mv-section h2 {
    margin-bottom: 10px;
  }
  
  /* Our Team Section */
  .our-team {
    padding: 40px 20px;
    background-color: #fff;
    text-align: center;
  }
  
  .team-gallery {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 20px;
  }
  
  .team-gallery img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
  }
  
  /* Call to Action */
  .cta {
    background: #333;
    color: #fff;
    padding: 40px 20px;
    text-align: center;
  }
  
  .cta h2 {
    margin-bottom: 10px;
  }
  
  .cta-button {
    padding: 10px 20px;
    background: #ff6347;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .cta-button:hover {
    background: #e5533f;
  }
  