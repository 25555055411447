.how-it-works {
  text-align: center;
  padding: 20px;
}

.subtitle {
  color: gray;
  margin-top: 5px;
}

.note {
  color: gray;
  margin-top: 5px;
}

.steps-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 15px;
}

.step-number {
  /* background-color: #fcbf16; */
  color: #fcbf16;
  font-size: 86px;
  font-weight: bold;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  z-index: -1;
  
}

.step-content {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
  width: 200px;
  margin-right: 50px;
  min-height: 160px;
}

.step-content h3 {
  margin-top: 0;
}

.step-content p {
  margin: 0;
}
