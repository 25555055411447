.top-rated-tours {
  text-align: center;
  padding: 40px 20px;
}

.top-rated-tours h2 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.top-rated-tours p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.tours-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-items: center;
}

.tour-card {
  background: #ffffff;
  border-radius: 10px;
  max-width: 350px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s;
}

.tour-card:hover {
  transform: translateY(-10px);
}

.tour-card img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.tour-info {
  padding: 20px;
  text-align: left;
}

.tour-info h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.tour-info p {
  font-size: 1rem;
  margin-bottom: 10px;
}

.tour-info .rating {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: #ffd700;
}

.tour-info .rating span {
  margin-right: 5px;
}

.tour-info a {
  display: inline-block;
  margin-top: 10px;
  font-size: 1rem;
  color: #007BFF;
  text-decoration: none;
}

.tour-info a:hover {
  text-decoration: underline;
}

/* Media Queries for Responsive Design */
@media (max-width: 1200px) {
  .tours-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .top-rated-tours h2 {
    font-size: 2rem;
  }

  .top-rated-tours p {
    font-size: 1rem;
  }

  .tours-grid {
    grid-template-columns: 1fr;
  }

  .tour-card {
    max-width: 100%;
  }

  .tour-info h3 {
    font-size: 1.3rem;
  }

  .tour-info p {
    font-size: 0.9rem;
  }

  .tour-info .rating {
    font-size: 1rem;
  }

  .tour-info a {
    font-size: 0.9rem;
  }
}

@media (max-width: 576px) {
  .top-rated-tours h2 {
    font-size: 1.5rem;
  }

  .top-rated-tours p {
    font-size: 0.9rem;
  }

  .tour-info h3 {
    font-size: 1.2rem;
  }

  .tour-info p {
    font-size: 0.8rem;
  }

  .tour-info .rating {
    font-size: 0.9rem;
  }

  .tour-info a {
    font-size: 0.8rem;
  }
}
