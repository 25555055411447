.footer {
    background-color: #000;
    color: #fff;
    padding: 0px 8% 2% 8%;
    font-family: Arial, sans-serif;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
}

.footer-item {
    flex: 1;
    min-width: 200px;
    margin: 10px 0;
}

.footer-logo {
    max-width: 150px;
    margin-bottom: 20px;
}

.footer-item h3 {
    font-size: 1.5em;
    margin-bottom: 20px;
}

.footer-item ul {
    list-style: none;
    padding: 0;
}

.footer-item ul li {
    margin-bottom: 10px;
    font-size: 1em;
}

.footer-item p {
    font-size: 1em;
}

.footer-search {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.footer-search input {
    padding: 10px;
    border: none;
    border-radius: 5px 0 0 5px;
    outline: none;
}

.footer-search button {
    padding: 10px;
    background-color: #fcbf49;
    border: none;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
}

.footer-bottom {
    text-align: center;
    margin-top: 20px;
}

.footer-bottom p {
    margin: 0;
    font-size: 1em;
}

.developer {
    color: #fcbf49;
}

/* Media Queries for Responsive Design */
@media (max-width: 992px) {
    .footer {
        padding: 40px 60px 20px 60px;
    }

    .footer-item {
        min-width: 180px;
    }

    .footer-item h3 {
        font-size: 1.3em;
    }

    .footer-item ul li,
    .footer-item p,
    .footer-bottom p {
        font-size: 0.9em;
    }
}

@media (max-width: 768px) {
    .footer {
        padding: 30px 40px 15px 40px;
    }

    .footer-item {
        min-width: 150px;
    }

    .footer-item h3 {
        font-size: 1.2em;
    }

    .footer-item ul li,
    .footer-item p,
    .footer-bottom p {
        font-size: 0.85em;
    }

    .footer-search input,
    .footer-search button {
        padding: 8px;
    }
}

@media (max-width: 576px) {
    .footer {
        padding: 20px 20px 10px 20px;
    }

    .footer-container {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .footer-item {
        min-width: 100%;
        text-align: center;
    }

    .footer-item h3 {
        font-size: 1.1em;
    }

    .footer-item ul li,
    .footer-item p,
    .footer-bottom p {
        font-size: 0.8em;
    }

    .footer-search {
        flex-direction: column;
        align-items: stretch;
        margin-top: 10px;
    }

    .footer-search input,
    .footer-search button {
        width: 100%;
        padding: 8px;
        margin: 5px 0;
    }

    .footer-search button {
        border-radius: 5px;
    }
}
