/* src/components/WeddingCarBooking.css */

.booking-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #fff;
  }
  
  .illustration {
    flex: 1;
  }
  
  .illustration img {
    width: 100%;
    max-width: 400px;
    margin-top: 5%;
    height: auto;
  }
  
  .booking-form {
    flex: 1;
    margin-right: 10%;
    max-width: 400px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .booking-form h2 {
    margin-bottom: 20px;
    color: #333;
    text-align: center;
  }
  
  .booking-form form {
    display: flex;
    flex-direction: column;
  }
  
  .booking-form label {
    margin-bottom: 10px;
    color: #666;
  }
  
  .booking-form label select,
  .booking-form label input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .booking-form .price-and-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .booking-form .price-and-button span {
    font-size: 16px;
    color: #333;
  }
  
  .booking-form .price-and-button button {
    padding: 10px 20px;
    background-color: #f4b400;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .booking-form .price-and-button button:hover {
    background-color: #e0a800;
  }
  