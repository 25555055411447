.mainContainer {
    display: flex;
    flex-direction: column;
    width: 35.91313rem;
    flex-shrink: 0;
    gap: 2rem;
    
}

.imgContainer {
    display: flex;
    width: 35.91313rem;
    height: 23.96188rem;
    flex-shrink: 0;
}

.houseImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.bottomContainer {
    display: flex;
    flex-direction: column;
    margin-left: 1.5rem;
    gap: 0.5rem;
    align-items: flex-start;
}

.bottomContainer > a{
    text-decoration: underline;
    color: #BC5612;
}

.houseAddress {
    font-family: Roboto;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 139.523%;
    /* display: none; */
    max-width: 100%;
    padding: 0;
    margin: 0;

      
}

.featuresContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.features {
    display: flex;
}

.featuresContainer > p {
    font-family: Roboto;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.78125rem;
    max-width: 100%;
    padding: 0;
    margin: 0;

}

.features > p {
    color: rgba(22, 22, 22, 0.7);
    font-family: Roboto;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.67438rem;
    max-width: 100%;
    padding: 0;
    margin: 0;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    

}

.features > span {
    color: rgba(22, 22, 22, 0.7);
    font-family: Roboto;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.67438rem;
    max-width: 100%;
    padding: 0;
    margin: 0;

}



.detailsLink {
    color: #BC5612;
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.78125rem;
    cursor: pointer;
    max-width: 100%;
    padding: 0;
    margin: 0;
    text-align: start;

}

.moreDetailsButton{
    background: none;
    border: none;
    outline: none;
}



@media (max-width: 1024px) {
    .mainContainer {
        width: 18.91313rem;
    }
    
    .houseImage {
        width: 18.91313rem;
    }
    
    .bottomContainer {
        margin-left: 1rem;
        gap: 0.3rem;
    }
    
    .houseAddress {
        font-size: 1.2rem;

    }
    
  
    
    .featuresContainer > p {
        font-size: 0.7rem;

    }
    
    .features > p {
        font-size: 0.7rem;
        margin-right: 0.3rem;
        margin-left: 0.3rem;
    }
    
    .features > span {
        font-size: 0.7rem;

    }
    
    .detailsLink {
        font-size: 0.7rem;

    }
    
}



@media (max-width: 768px) {
    .mainContainer {
        width: 18.91313rem;
    }
    

    .imgContainer {
        display: flex;
        width: 18.91313rem;
        height: 12.96188rem;
        flex-shrink: 0;
    }
    
    .houseImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    
    .bottomContainer {
        margin-left: 1rem;
        gap: 0.3rem;
    }
    
    .houseAddress {
        font-size: 1.2rem;

    }
    
  
    
    .featuresContainer > p {
        font-size: 0.7rem;

    }
    
    .features > p {
        font-size: 0.7rem;
        margin-right: 0.3rem;
        margin-left: 0.3rem;
    }
    
    .features > span {
        font-size: 0.7rem;

    }
    
    .detailsLink {
        font-size: 0.7rem;

    }
    
}





@media (max-width: 600px) {
    .mainContainer {
        width: 15.91313rem;
        gap: 1rem;
    }
    
    .imgContainer {
        display: flex;
        width: 15.91313rem;
        height: 9.96188rem;
        flex-shrink: 0;
    }
    
    .houseImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    
    .bottomContainer {
        margin-left: 0.5rem;
        gap: 0.2rem;
    }
    
    .houseAddress {
        font-size: 0.8rem;

    }
    
  
    
    .featuresContainer > p {
        font-size: 0.7rem;

    }
    
    .features > p {
        font-size: 0.6rem;
        margin-right: 0.2rem;
        margin-left: 0.2rem;
    }
    
    .features > span {
        font-size: 0.6rem;

    }
    
    .detailsLink {
        font-size: 0.6rem;

    }
    
}




