.booking-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #fff;
}

.illustration {
  flex: 1;
  width: 100%;
  margin-bottom: 20px; /* Added space between the illustration and form on mobile */
}

.illustration img {
  width: 100%;
  max-width: 800px;
  height: auto;
}

.booking-form {
  flex: 1;
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.booking-form p {
  text-align: left;
}

.booking-form h2 {
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.booking-form form {
  display: flex;
  flex-direction: column;
}

.booking-form label {
  margin-bottom: 10px;
  color: #666;
}

.booking-form label select,
.booking-form label input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.booking-form .price-and-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.booking-form .price-and-button span {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px; /* Added space between the price and button on mobile */
}

.booking-form .price-and-button button {
  padding: 10px 20px;
  background-color: #f4b400;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.booking-form .price-and-button button:hover {
  background-color: #e0a800;
}

/* Media Queries for Mobile Responsiveness */
@media (min-width: 768px) {
  .booking-container {
      flex-direction: row;
  }

  .booking-form .price-and-button {
      flex-direction: row;

      justify-content: space-between;
  }

  .booking-form .price-and-button span {
      margin-bottom: 0; /* Remove space between price and button on larger screens */
  }
}

  /* Media Queries for Mobile Devices */
@media (max-width: 768px) {
  .illustration{
      display: none;
  }

  .booking-container .booking-form {
    /* flex: 1; */
    max-width: 300px;
    margin-left: 10%;

    padding-right: 60PX;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .booking-container {
    display: flex;
    max-width: 100%;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #ffffff;
  }
  

}