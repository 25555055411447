.cab-booking {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background-color: rgb(255, 255, 255);
  }
  
  .booking-form-container {
    width: 30%;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    margin-left:12%;
    box-shadow: 6px 6px 6px 6px rgba(0.1, 0.1, 0.1, 0.1);
  }
  
  .booking-form h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333333;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    text-align: left;
    margin-bottom: 5px;
    font-weight: 600;
    color: #555555;
  }
  
  .form-group input,
  .form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #dddddd;
    border-radius: 4px;
  }
  
  .vehicle-types {
    display: flex;
    justify-content: space-between;
  }
  
  .vehicle-icon {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .price-note {
    font-size: 14px;
    color: #333333;
  }
  
  .price-note-detail {
    color: red;
  }
  
  .book-now-button {
    width: 100%;
    padding: 10px;
    background-color: #f0ad4e;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  .book-now-button:hover {
    background-color: #ec971f;
  }
  
  .booking-image img {
    max-width: 100%;
    height: auto;
    margin-right: 100px;
    border-radius: 8px;
    pointer-events: none;

  }
  

  /* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
    .cab-booking {
      flex-direction: column;
      align-items: center;
    }
  
    .booking-form-container {
      width: 80%;
      margin-bottom: 20px;
      margin: auto;
    }
  
    .booking-image {
      width: 70%;
      margin: auto;
    }
  }
  
  @media (max-width: 480px) {
    .booking-form-container {
      width: 100%;
      padding: 15px;
    }
  
    .booking-image {
      width: 100%;
    }
  
    .booking-form h2 {
      font-size: 20px;
    }
  
    .form-group input,
    .form-group select {
      padding: 8px;
    }
  
    .book-now-button {
      font-size: 14px;
    }
  
    .vehicle-icon {
      width: 30px;
      height: 30px;
    }
  }