.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000;
  padding: 25px 20px;
  position: fixed; /* Make the header fixed */
  top: 0; /* Position it at the top */
  left: 0;
  right: 0;
  z-index: 1000; /* Ensure it is above other content */
}

.logo{
  max-height: 50px;
}

.logo img {
  margin-top: -35px;
  height: 120px;
}

.nav {
  display: flex;
  flex: 1;
  justify-content: center;
}

.nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav ul li {
  margin-right: 20px;
}

.nav ul li a {
  font-size: 25px;
  color: #fff;
  text-decoration: none;
}

.nav ul li a.active {
  color: #ffc107;
}

.call-now {
  background-color: #ffc107;
  border: none;
  padding: 10px 20px;
  color: #000;
  cursor: pointer;
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  height: 20px;
  cursor: pointer;
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 2px 0;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .nav {
    display: none;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    background-color: #000;
    padding: 20px;
  }

  .nav ul {
    flex-direction: column;
  }

  .nav ul li {
    margin-bottom: 20px;
  }

  .hamburger {
    display: flex;
  }

  .header.active .nav {
    display: flex;
  }
}
