.carousel-container {
    width: 80%;
    margin: auto;
    text-align: center;
  }
  
  .carousel-container h2 {
    font-size: 2rem;
    color: #333;
  }
  
  .carousel-container p {
    color: #555;
  }
  
  .carousel {
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
  
  .carousel-items-container {
    overflow: hidden;
    width: 100%;
  }
  
  .carousel-items {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: calc(25% * 3); /* Adjust based on the number of visible items */
  }
  
  .carousel-item {
    min-width: 0%; /* Adjust based on the number of visible items */
    box-sizing: border-box;
    padding: 10px;
    transition: opacity 0.3s ease-in-out;
  }
  
  .carousel-item img {
    width: 100%;
    border-radius: 10px;
  }
  
  .carousel-item h3 {
    margin: 10px 0;
    color: #d32f2f;
  }
  
  .carousel-item p {
    color: #555;
  }
  
  .carousel-item .rating {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .carousel-item .stars {
    color: gold;
    margin-right: 5px;
  }
  
  .book-button {
    background-color: #ff9800;
    border: none;
    border-radius: 5px;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .book-button:hover {
    background-color: #e68900;
  }
  
  .arrow {
    background: #fff;
    border: none;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 40px;
    height: 40px;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .left-arrow {
    left: 10px;
  }
  
  .right-arrow {
    right: 10px;
  }
  