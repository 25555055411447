.how-it-works {
    text-align: center;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .how-it-works h2 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .how-it-works p {
    color: #7d7d7d;
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  .steps {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .step {
    background: #f8f8f8;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 250px;
    text-align: left;
  }
  
  .step .number {
    background: #fdbb30;
    border-radius: 50%;
    color: white;
    font-size: 1.5rem;
    height: 40px;
    line-height: 40px;
    text-align: center;
    width: 40px;
    margin-bottom: 10px;
  }
  
  .step h3 {
    font-size: 1.25rem;
    margin-bottom: 10px;
  }
  
  .step p {
    color: #7d7d7d;
    font-size: 0.9rem;
  }
  