.services {
    text-align: center;
    padding: 40px 20px;
}

.services h2 {
    font-size: 2.5em;
    margin-bottom: 10px;
    font-weight: bold;
}

.services .container {
    width: 70%;
    margin: auto;
}

.services-description {
    width: 80%;
    margin: auto;
    font-size: 1.2em;
    margin-bottom: 40px;
    color: #555;
}

.services-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    justify-items: center;
}

.service-item {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    width: 270px;
    text-align: center;
    transition: transform 0.3s;
}

.service-item:hover {
    transform: translateY(-10px);
}

.service-icon-container {
    /* background-color: #fcbf49; Circle color */
    border-radius: 50%;
    padding: 20px;
    display: inline-block;
    margin-bottom: 20px;
}

.service-icon {
    width: 180px;
    height: 180px;
}

.service-item h3 {
    font-size: 1.5em;
    margin: 10px 0;
    font-weight: bold;
}

.service-item p {
    font-size: 1em;
    color: #555;
}

/* Media Queries for Responsive Design */
@media (max-width: 992px) {
    .services h2 {
        font-size: 2em;
    }

    .services .container {
        width: 85%;
    }

    .services-description {
        width: 90%;
        font-size: 1.1em;
    }

    .services-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
    }

    .service-item {
        width: 220px;
        padding: 15px;
    }

    .service-icon {
        width: 150px;
        height: 150px;
    }

    .service-item h3 {
        font-size: 1.3em;
    }

    .service-item p {
        font-size: 0.9em;
    }
}

@media (max-width: 768px) {
    .services h2 {
        font-size: 1.8em;
    }

    .services .container {
        width: 90%;
    }

    .services-description {
        width: 95%;
        font-size: 1em;
    }

    .services-grid {
        grid-template-columns: 1fr;
        gap: 10px;
    }

    .service-item {
        width: 100%;
        padding: 15px;
    }

    .service-icon {
        width: 130px;
        height: 130px;
    }

    .service-item h3 {
        font-size: 1.2em;
    }

    .service-item p {
        font-size: 0.9em;
    }
}

@media (max-width: 576px) {
    .services h2 {
        font-size: 1.5em;
    }

    .services .container {
        width: 100%;
    }

    .services-description {
        width: 100%;
        font-size: 0.9em;
    }

    .service-item {
        width: 100%;
        padding: 10px;
    }

    .service-icon {
        width: 100px;
        height: 100px;
    }

    .service-item h3 {
        font-size: 1em;
    }

    .service-item p {
        font-size: 0.8em;
    }
}
