/* src/components/CarCard.css */

.car-slider {
  width: 70%;
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 15px;
  margin-top: 8%;
  margin-left: auto;
  margin-right: auto;
  
  /* margin-left: 12%; */

  box-shadow: 5px 5px 5px 5px rgba(0.1, 0.1, 0.1, 0.1);
  overflow: hidden;
  /* margin: auto; */
  padding: 35px;
}

.car-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.car-details {
  flex: 1;
  padding: 20px;
}

.car-details h2 {
  color: #d32f2f;
}

.car-details p {
  margin: 10px 0;
  color: #555;
  text-align: justify;
  min-height: 160px;
}

.rating {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.stars {
  color: gold;
  margin-right: 10px;
}

.thumbnails {
  display: flex;
  gap: 10px;
}

.thumbnails img {
  width: 100px;
  height: auto;
  border-radius: 5px;
  object-fit: cover;
  cursor: pointer;
  opacity: 0.6;
}

.thumbnails img.active,
.thumbnails img:hover {
  opacity: 1;
}

.arrows {
  background: #fff;
  border: none;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  font-size: 20px;
  cursor: pointer;
}

.leftarrowslider {
  margin-right: 20px;
}

.rightarrowslider {
  margin-left: 20px;
}

/* Media Queries for Responsive Design */
@media (max-width: 1200px) {
  .car-slider {
    width: 80%;
    padding: 30px;
  }

  .thumbnails img {
    width: 80px;
  }

  .arrows {
    width: 35px;
    height: 35px;
    font-size: 18px;
  }
}

@media (max-width: 992px) {
  .car-slider {
    flex-direction: column;
    padding: 25px;
  }

  .car-image {
    width: 100%;
    text-align: center;
  }

  .car-details {
    padding: 15px;
  }

  .thumbnails img {
    width: 70px;
  }

  .arrows {
    width: 30px;
    height: 30px;
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .car-slider {
    width: 80%;
    padding: 20px;
  }

  .car-details {
    padding: 10px;
  }

  .car-details p {
    min-height: 120px;
  }

  .thumbnails img {
    width: 60px;
  }

  .arrows {
    width: 25px;
    height: 25px;
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .car-slider {
    width: 80%;
    padding: 15px;
  }

  .car-details {
    padding: 5px;
  }

  .car-details p {
    min-height: 100px;
  }

  .thumbnails img {
    width: 50px;
  }

  .arrows {
    width: 20px;
    height: 20px;
    font-size: 12px;
  }
}
