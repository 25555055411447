.luxury {
  text-align: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.luxury h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.luxury p {
  color: #7d7d7d;
  font-size: 1rem;
  margin-bottom: 20px;
}

.car-cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.car-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 300px;
  max-height: 600px;
  text-align: left;
  position: relative;
}

.car-image {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
}

.car-card h3 {
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.car-card p {
  color: #7d7d7d;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.rating {
  color: #fdbb30;
  font-size: 1rem;
  position: absolute;
  top: 10px;
  right: 10px;
}

.book-button {
  background: #fdbb30;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  font-size: 1rem;
  margin-top: 10px;
  transition: background 0.3s;
}

.book-button:hover {
  background: #e5a821;
}

/* Media Queries for Mobile Responsiveness */
@media (min-width: 768px) {
  .car-cards {
      flex-direction: row;
      justify-content: center;
  }

  .car-card {
      width: 250px;
  }
}
